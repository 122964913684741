import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";

export async function callMsGraphProfileMe(accessToken) {
    const finalAccessToken = await getGraphAccessToken(accessToken);

    const headers = new Headers();
    const bearer = `Bearer ${finalAccessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };
    const graphRequest = {
        scopes: ["User.Read"]
    };
    return fetch("https://graph.microsoft.com/v1.0/me", options)
        .then(response => response.json())
        .catch(async (error) => {
            // Handle error
            return await msalInstance.acquireTokenPopup(graphRequest);
        });
}
export async function callGetAA(accessToken) {
    const finalAccessToken = await getAccessToken(accessToken);

    const headers = new Headers();
    const bearer = `Bearer ${finalAccessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(process.env.REACT_APP_ENDPOINT_APIGW + "/autoattendants", options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
export async function callUpdateAA(accessToken, data) {
    const finalAccessToken = await getAccessToken(accessToken);

    const headers = new Headers();
    const bearer = `Bearer ${finalAccessToken}`;

    const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': bearer
        },
        body: JSON.stringify(data)
    };

    return fetch(process.env.REACT_APP_ENDPOINT_APIGW + "/autoattendants", options);
}

async function getAccessToken(accessToken) {
    var returnAccessToken = accessToken;
    if (!returnAccessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
    
        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        returnAccessToken = response.accessToken;
    }
    return returnAccessToken;
}
async function getGraphAccessToken(accessToken) {
    var returnAccessToken = accessToken;
    const graphRequest = {
        scopes: ["User.Read"]
    };
    if (!returnAccessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
    
        const response = await msalInstance.acquireTokenSilent({
            ...graphRequest,
            account: account
        });
        returnAccessToken = response.accessToken;
    }
    return returnAccessToken;
}