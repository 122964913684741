import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    load: 'languageOnly',
    fallbackLng: 'en',
    supportedLngs: ['de', 'en'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      de: {
        translation: {
          "App.info": "Dies sind alle dir zur Verfügung stehenden Hotlines. Bitte nutze deine Telefonnummer aus dem Profil oder gib eine Telefonnummer mit Ländervorwahl an (z.B. +49 für Deutschland).",
          "App.noAuthNoUser": "Es ist kein Benutzer angemeldet!",
          "App.noAuthSignIn": "Bitte melde dich an, um deine Optionen zu sehen.",
          "UserProfile.welcome": "Willkommen <i>{{givenName}}</i>! Deine Mobilnummer ist <i>{{number}}</i>.",
          "UpdateButton.title": "Speichern",
          "ProtocolButton.title": "Protokoll anzeigen",
          "CopyPhoneButton.title": "Nummer einfügen",
          "ProtocolElement.title": "Protokoll",
          "Info.toastify" : "Die Nummer wird in Kürze aktualisiert",
          "Error.toastify" : "Ein Prozess läuft im Hintergrund. Bitte versuche es in ein paar Minuten erneut",
          "Error.phoneNumber" : "Die Telefonnummer muss mit einem '+' beginnen und nur Ziffern enthalten",
          "Error.NotAuthorized" : "Du bist nicht berechtigt, diese Hotline zu aktualisieren"
        }
      },
      en: {
        translation: {
          "App.info": "These are all the hotlines available to you. Please use your phone number from the profile or enter a phone number with country code (e.g. +49 for Germany).",
          "App.noAuthNoUser": "No user is signed in!",
          "App.noAuthSignIn": "Please sign in to see your options.",
          "UserProfile.welcome": "Welcome <i>{{givenName}}</i>! Your mobile number is <i>{{number}}</i>.",
          "UpdateButton.title": "Save",
          "ProtocolButton.title": "Show protocol",
          "CopyPhoneButton.title": "Paste number",
          "ProtocolElement.title": "Protocol",
          "Info.toastify" : "The number will be updated shortly",
          "Error.toastify" : "A background process is ongoing. Please try again in a few minutes",
          "Error.phoneNumber" : "The phone number must start with a '+' and include only digits",
          "Error.NotAuthorized" : "You are not authorized to update this hotline"
        }
      }
    }
  });

export default i18n;