import { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal, useMsalAuthentication, MsalProvider } from '@azure/msal-react';
import { loginRequest } from "./authConfig";
import { InteractionType } from '@azure/msal-browser';

import {callGetAA, callUpdateAA, callMsGraphProfileMe} from './hooks/useFetchWithMsal';
import { msalInstance } from './index';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';

import { useTranslation, Trans } from 'react-i18next';

function handleLogin() {
  msalInstance.loginRedirect(loginRequest);
}

function getUpdatedAutoAttendants(autoAttendants, id, phoneNumber) {
  const updatedAutoAttendants = autoAttendants.map((attendant) => {
    if (id && attendant.id === id) {
      return {
        ...attendant,
        phoneNumber: phoneNumber
      };
    }
    return {
      ...attendant,
      phoneNumber: ""
    };
  });
  return updatedAutoAttendants;
}

function UserProfile({setMobilePhone}) {
  const [profile, setProfile] = useState(null);

  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (isAuthenticated && !profile) {
      callMsGraphProfileMe().then(response => {
        setMobilePhone(response.mobilePhone);
        setProfile(response)
      });
    }
  });
  return (
    <>
      {profile && 
        <p><Trans i18nKey="UserProfile.welcome" givenName={profile.givenName} number={profile.mobilePhone}>
          {{givenName: profile.givenName}} {{number: profile.mobilePhone}}.
          </Trans>
        </p>
      }
    </>
  );
}
function UpdateButton({ autoAttendant, isLoading, setLoadingButtonId, setNewFetch, isClicked, setIsClicked}) {
  const { t } = useTranslation();

  const handleClick = () => {
    if (!isClicked) {
      
      setLoadingButtonId(autoAttendant.id);
      isLoading ? setIsClicked(false) : setIsClicked(true);

      const data = {
        id: autoAttendant.id,
        displayName: autoAttendant.displayName,
        phoneNumber: autoAttendant.phoneNumber
      };
      callUpdateAA(null, data)
        .then(response => {

          if (response.status === 200){
            toast.info(t("Info.toastify"), {
              autoClose: 15000,
            });
            setNewFetch(1);
            return response.json();
          } 
          else if (response.status === 409){
            toast.error(t("Error.toastify"));
            setNewFetch(0);
            return response.json();
          }
          else if (response.status === 400){
            toast.error(t("Error.phoneNumber"));
            setNewFetch(0);
            return "The phone number must start with a '+' and include only digits"
          }
          else if (response.status === 403){
            toast.error(t("Error.NotAuthorized"));
            setNewFetch(0);
            return "You are not authorized to update this hotline"
          } 
          else {
            setNewFetch(0);
          }
        })
        .then(result => {
          // Handle the result of the POST request
          console.log(result);
        })
        .catch(error => {
          // Handle any errors that occurred during the request
          console.error(error);
        });
    }
  };

  return (
    <button className="btn btn-outline-secondary" type="button"
      id={autoAttendant.id}
      onClick={handleClick}
      disabled={isClicked} title={t("UpdateButton.title")} >
      { isLoading ? (
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      ) : 
        <FontAwesomeIcon
          icon={solid("floppy-disk")}
          style={{
            cursor: "pointer",
            color: "#000000",
            pointerEvents: isClicked ? "none" : "auto",
            opacity: isClicked ? 0.3 : 1
          }}
        />}
    </button>
  );
}
function ProtocolButton({ toggleFunction }) {
  const { t } = useTranslation();
  return (
    <button className="btn btn-outline-secondary" type="button" 
      onClick={toggleFunction} title={t("ProtocolButton.title")}>
      <FontAwesomeIcon
        icon={solid("rectangle-list")}
        style={{
          cursor: "pointer",
          color: "#000000"
        }}
      />
    </button>
  );
}
function CopyPhoneButton({ copyFunction }) {
  const { t } = useTranslation();
  return (
    <button className="btn btn-outline-secondary" type="button" 
      onClick={copyFunction} title={t("CopyPhoneButton.title")}>
      <FontAwesomeIcon
        icon={solid("mobile-screen")}
        style={{
          cursor: "pointer",
          color: "#000000"
        }}
      />
    </button>
  );
}
function ProtocolElement({ isVisible, autoAttendant }) {
  const { t } = useTranslation();
  return (
    isVisible && <div className="col mt-2">
      <h3>{t("ProtocolElement.title")}</h3>
      {autoAttendant.protocolItems.map(protocolItem => (
        <div key={protocolItem.runId} className="row">
          <div className="col">{new Date(protocolItem.timestampPwsh).toLocaleString()}</div>
          <div className="col">{protocolItem.email}</div>
        </div>
      ))}
    </div>
  );
}

function Hotline({ autoAttendant, autoAttendants, autoAttendantsSetter, mobilePhone, isLoading, setIsLoading, setLoadingButtonId, setNewFetch, isClicked, setIsClicked}) {
  const [isVisibleProtocol, setIsVisibleProtocol] = useState(false);
  const toggleVisibilityProtocol = () => {
    setIsVisibleProtocol(!isVisibleProtocol);
  };
  const copyFunction = () => {
    console.log("copy mobile phone number to input field: " + mobilePhone.mobilePhone);
    autoAttendantsSetter(getUpdatedAutoAttendants(autoAttendants, autoAttendant.id, mobilePhone.mobilePhone));
  };
  return (
    <div key={autoAttendant.id} className="mt-3 p-2 border border-dark rounded row">
      <h2 className='mb-2'>{autoAttendant.displayName} <small className="text-body-secondary">({autoAttendant.hotlineNumber})</small></h2>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon
            icon={solid("phone")}
            style={{
              color: "#000000"
            }}
          /></span>
          <input type="text" className="form-control" aria-label="fon" aria-describedby="basic-addon1" value={autoAttendant.phoneNumber} onChange={(event) => {
            autoAttendantsSetter(getUpdatedAutoAttendants(autoAttendants, autoAttendant.id, event.target.value));
          }} />
          <CopyPhoneButton copyFunction={copyFunction} />
          <UpdateButton autoAttendant={autoAttendant} isLoading={isLoading} setLoadingButtonId={setLoadingButtonId} setNewFetch={setNewFetch} isClicked={isClicked} setIsClicked={setIsClicked}/>
          <ProtocolButton toggleFunction={toggleVisibilityProtocol} />
        </div>

      <div className="w-100"></div>
      <ProtocolElement isVisible={isVisibleProtocol} autoAttendant={autoAttendant} />
    </div>
  );
}

function HotlineList({mobilePhone, setIsLoading, isLoading, setIsClicked, isClicked}) {
  mobilePhone = {mobilePhone};
  const [loadingButtonId, setLoadingButtonId] = useState(null);
  const [newFetch, setNewFetch] = useState(2);
  
  const handleUpdateComplete = () => {
    setLoadingButtonId(null);
    setNewFetch(2);
    setIsClicked(false);
    setIsLoading(false);
  };
  

  const [autoAttendants, setAutoAttendants] = useState([]);
  const wrapperSetAutoAttendants = useCallback(value => {
    setAutoAttendants(value);
  }, [setAutoAttendants]);
  const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, {
    ...loginRequest,
    redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI, // e.g. /redirect,
  });
  const isAuthenticated = useIsAuthenticated();
  
  useEffect(() => {
    if(result){
      console.log(result);
      //callGetAA().then(response => setAutoAttendants(response));
    }
  }, [result]);

  const { msal } = useMsal();

  useEffect(() => {
    console.log("debug isAuthenicated - isActiveAccount - autoAttendants.length: " + isAuthenticated + " - " + msalInstance.getActiveAccount() + " - " + autoAttendants.length);
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      console.log("need to set active account")
      msalInstance.setActiveAccount(msalInstance.getAllAccounts().find(account => account.username.toLowerCase().includes("exxeta"))[0]);
    }
    console.log("debug isAuthenicated - isActiveAccount - autoAttendants.length: " + isAuthenticated + " - " + msalInstance.getActiveAccount() + " - " + autoAttendants.length);
    if (isAuthenticated && msalInstance.getActiveAccount() && autoAttendants.length === 0) {
        console.log("is authenicated: " + isAuthenticated);
        setTimeout(() => {
          callGetAA().then(response => {
            // make sure the phoneNumber is initialized to have React track the state
            setAutoAttendants(getUpdatedAutoAttendants(response))});
        }, 500);
      }
    if (isAuthenticated && msalInstance.getActiveAccount() && newFetch === 1) {      
      console.log("is authenicated: " + isAuthenticated);

      setTimeout(() => {
          callGetAA().then(response => {
            // make sure the phoneNumber is initialized to have React track the state
            setAutoAttendants(getUpdatedAutoAttendants(response))});
            handleUpdateComplete();
        }, 15000);
    } else {      
      handleUpdateComplete();
    }
  }, [isAuthenticated, autoAttendants.length, newFetch]);

  if (error) {
      return <div>Error: {error.message}</div>;
  }
  return (
    <div>
        {autoAttendants.map(autoAttendant => (
          <Hotline 
          key={autoAttendant.id} 
          autoAttendant={autoAttendant} 
          autoAttendants={autoAttendants} 
          autoAttendantsSetter={wrapperSetAutoAttendants} 
          mobilePhone={mobilePhone} 
          isLoading={autoAttendant.id === loadingButtonId} 
          setIsLoading={setIsLoading} 
          setLoadingButtonId={setLoadingButtonId} 
          setNewFetch={setNewFetch} 
          isClicked={autoAttendant.id === loadingButtonId} 
          setIsClicked={setIsClicked}
          />
        ))}
    </div>
  );
}

function App({ instance }) {
  const { t } = useTranslation();
  
  const [mobilePhone, setMobilePhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const wrapperSetMobilePhone = useCallback(value => {
    console.log("UserProfile setMobilePhone: " + value);
    setMobilePhone(value);
  }, [setMobilePhone]);
  const authRequest = {
    ...loginRequest,
  };
  return (
    <MsalProvider instance={instance}>
    <div className="container-xxl">
      <header>
        <h1>Teams AutoAttendant/CallQueue Operator (Taco)</h1>
      </header>
          <ToastContainer
          position="top-right"
          />
          <AuthenticatedTemplate>
            <UserProfile setMobilePhone={wrapperSetMobilePhone}/>
            <p>{t("App.info")}</p>
            <HotlineList 
            mobilePhone={mobilePhone} 
            setIsLoading={setIsLoading}
            setIsClicked={setIsClicked}
            isLoading={isLoading}
            isClicked={isClicked}
            />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <p>{t("App.noAuthNoUser")}</p>
            <button className="btn border" onClick={handleLogin}>{t("App.noAuthSignIn")}</button>
          </UnauthenticatedTemplate>
    </div>
    </MsalProvider>
  );
}
export default App;